<template>
  <div class="login-wrap">
    <div class="top-form">
      <img class="logo-img" :src="imgLogo" />
      <el-button
        @click="navofficialsite"
        class="access-btn"
        size="small"
        type="primary"
        >访问无觅官网</el-button
      >
      <p class="split-line"></p>
    </div>
    <div class="login-form">
      <div class="title-form">
        <h2>子账号登录</h2>
      </div>
      <div class="bind-wrap">
        <el-form ref="loginForm" :rules="loginRules" :model="loginForm">
          <el-form-item prop="username">
            <el-input
              ref="username"
              clearable
              v-model="loginForm.username"
              placeholder="请输入用户名"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              placeholder="请输入密码"
              ref="password"
              clearable
              type="password"
              show-password
              v-model="loginForm.password"
            >
            </el-input>
          </el-form-item>
          <el-button
            :loading="loading"
            class="loginbtn"
            type="primary"
            @click.native.prevent="handleLogin"
            >登录</el-button
          >
        </el-form>
      </div>
      <p @click="navaccount" class="subaccount">主账号登录</p>
    </div>
  </div>
</template>
<script>
import md5 from "js-md5";
import logo from "../../../assets/logo.png";
import { officalWebsite } from "@/utils/public";
import navigation from "@/utils/navigation";
export default {
  data() {
    const validateUserName = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error("请输入用户名"));
      } else {
        callback();
      }
    };
    const validatePassword = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };
    return {
      imgLogo: logo,
      loginForm: {
        username: "",
        password: "",
      },
      loginRules: {
        username: [
          { required: true, trigger: "blur", validator: validateUserName },
        ],
        password: [
          { required: true, trigger: "blur", validator: validatePassword },
        ],
      },
      vervalue: "",
      verifibtn: false,
      loading: false,
    };
  },
  components: {},
  methods: {
    navaccount() {
      navigation.navigateTo({
        url: "login",
      });
    },
    navofficialsite() {
      window.location.href = officalWebsite;
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        console.log(valid);
        if (valid) {
          this.loading = true;
          const data = {
            Username: this.loginForm.username,
            Password: md5(this.loginForm.password),
          };
          this.$store
            .dispatch("sub/login", { ...data })
            .then((res) => {
              if (res.RetCode == 0) {
                this.$router.push({
                  path: "/",
                });
              } else {
                this.$message.error(res.Message);
              }
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.bind-wrap {
  width: 400px;
  margin: auto;
  margin-top: 76px;
  .el-form-item {
    margin-bottom: 30px;
  }
  ::v-deep .el-input__inner {
    border-radius: 5px;
  }
  ::v-deep .el-input-group__append {
    border: none;
  }

  .loginbtn {
    font-size: 16px;
    width: 100%;
    border-radius: 5px;
    // background: linear-gradient(
    //   90deg,
    //   rgba(74, 99, 240, 0.93) 0%,
    //   rgba(236, 179, 242, 1) 100%
    // );
    background: rgba(88, 151, 242, 1);
  }
  .verification-btn {
    width: 110px;
    border-radius: 5px;
    border: none !important;
    color: #fff;
    background: linear-gradient(
      90deg,
      rgba(74, 99, 240, 0.93) 0%,
      rgba(236, 179, 242, 1) 100%
    );
  }
  .accounttext {
    font-size: 14px;
    text-align: center;
    margin-top: 50px;
    .register {
      color: rgba(74, 99, 240, 0.93);
      cursor: pointer;
    }
  }
}
.login-wrap {
  width: 100%;
  height: 100%;
  background-size: cover;
  .top-form {
    width: 100%;
    height: 60px;
    position: absolute;

    .logo-img {
      width: 60px;
      height: 60px;
      margin-left: 50px;
      vertical-align: middle;
      text-align: center;
    }

    .access-btn {
      float: right;
      margin-top: 15px;
      margin-right: 50px;
      background: rgba(103, 149, 235, 1);
    }

    .split-line {
      height: 60px;
      //margin-top: 60px;
      border-top: 1px solid #e5e5e5;
    }
  }
  .login-form {
    width: 600px;
    height: 550px;
    background: rgba(255, 255, 255, 1);
    border-radius: 5px;
    border: solid 1px #e5e5e5;
    box-sizing: border-box;
    padding: 45px 20px;
    position: absolute;
    top: 100px;
    bottom: 0;
    left: 50%;
    margin-left: -300px;
    box-shadow: rgb(92 118 232) 0px 2px 4px -1px,
      rgb(0 0 0 / 12%) 0px -3px 0px 0px inset,
      rgb(0 0 0 / 12%) 0px 1px 0px 0px inset;
    .title-form {
      text-align: center;
      color: #000;
      margin-bottom: 45px;
    }
    .el-tabs {
      width: 400px;
      margin: auto;
    }
    ::v-deep .el-tabs__nav {
      transform: translateX(114px) !important;
    }
    ::v-deep .el-tabs__item {
      font-size: 16px;
    }
    ::v-deep .el-tabs__item.is-active {
      //   color: linear-gradient(
      //     90deg,
      //     rgba(74, 99, 240, 0.93) 0%,
      //     rgba(236, 179, 242, 1) 100%
      //   );
      color: rgba(74, 99, 240, 0.93);
    }
    ::v-deep .el-tabs__active-bar {
      background-color: #4a63f0ed;
    }
    ::v-deep .el-tabs__nav-wrap::after {
      background-color: transparent;
    }
     .subaccount {
      position: absolute;
      bottom: 25px;
      right: 52px;
      font-size: 14px;
      color: rgba(74, 99, 240, 0.93);
      border-bottom: 1px solid rgba(74, 99, 240, 0.93);
      cursor: pointer;
    }
  }
}
</style>